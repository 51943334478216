import axios from 'axios';
import { apiURI } from '../env';
import { AUTHORIZATION_KEY, REFRESH_KEY } from '../utils/const';

export const checkAuthTokens = async () => {
  if (
    (await localStorage.getItem(AUTHORIZATION_KEY)) !== null &&
    (await localStorage.getItem(REFRESH_KEY)) !== null
  ) {
    return true;
  } else {
    return false;
  }
};

export const getReqHeaders = async () => {
  return {
    'content-type': 'application/json',
    Authorization: `Bearer ${await localStorage.getItem(AUTHORIZATION_KEY)}`,
    refresh: `refresh ${await localStorage.getItem(REFRESH_KEY)}`,
  };
};

export const removeUser = async (user) => {
  await axios
    .delete(`${apiURI}dashboard/user`, {
      headers: await getReqHeaders(),
      data: {
        user: user,
      },
    })
    .then((res) => {
      //console.log(res.data);
    })
    .catch((err) => {
      // console.log(err);
      // dispatch({
      //   type: ACTION_TYPES.LOGIN_FAILED,
      //   payload: err,
      // });
    });
};

export class User {
  constructor(userData) {
    this.userData = userData;
  }

  async createUser() {
    await axios
      .post(`${apiURI}auth/register`, this.userData)
      .then((res) => {
        this.created_user = res.data.data;
        // console.log(this.created_user);
      })
      .catch((err) => {
        //console.log(err);
      });
  }
}
