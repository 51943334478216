import React, { useState, useContext, useEffect } from 'react';
import { Badge, Container, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  makeStyles,
  Select,
} from '@material-ui/core';
import { configUser, setSelectedUser } from '../redux/actions/auth.actions';
import { fetchHotelsList } from '../redux/actions/cluster.actions';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const UserCrud = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { loading, err, hotelList, markets } = getClusterDataSet;

  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, auth_err, selectedUser } = auth;

  const [selectedProperty, setSelectedProperty] = useState(
    selectedUser !== undefined && selectedUser !== null
      ? selectedUser.application.properties.length > 0
        ? selectedUser.application.properties
        : []
      : []
  );
  const [selectedPropertyIndx, setSelectedPropertyIndx] = useState(0);
  const [selectedMarket, setSelectedMarket] = useState();
  const [selectedMarketIndx, setSelectedMarketIndx] = useState();
  const [selectedMarkets, setSelectedMarkets] = useState(
    selectedUser !== undefined && selectedUser !== null
      ? selectedUser.application.destinations.length > 0
        ? selectedUser.application.destinations
        : []
      : []
  );

  const handleMarketChange = (event) => {
    if (event.target.value >= 0) {
      const newMarket = markets[event.target.value];
      setSelectedMarket(newMarket.id);
      setSelectedMarketIndx(event.target.value);

      if (selectedMarkets.length > 0) {
        let isAvailable = selectedMarkets.find((e) => e.id === newMarket.id);

        if (isAvailable === undefined) {
          setSelectedMarkets((arr) => [...arr, newMarket]);
        }
      } else {
        setSelectedMarkets((arr) => [...arr, newMarket]);
      }
    }
  };

  const handlePropertyChange = (event) => {
    const newHotel = hotelList[event.target.value];
    setSelectedPropertyIndx(event.target.value);
    if (selectedProperty.length > 0) {
      let isAvailable = selectedProperty.find((e) => e.id === newHotel.id);

      if (isAvailable === undefined) {
        setSelectedProperty((arr) => [...arr, newHotel]);
      } else {
      }
    } else {
      setSelectedProperty((arr) => [...arr, newHotel]);
    }
  };

  const removeSelectedProperty = (id) => {
    let updatedArr = [...selectedProperty];

    updatedArr = updatedArr.filter(function (item) {
      return item.id !== id;
    });
    setSelectedProperty(updatedArr);
  };

  const removeSelectedMarket = (id) => {
    let updatedArr = [...selectedMarkets];

    updatedArr = updatedArr.filter(function (item) {
      return item.id !== id;
    });

    setSelectedMarkets(updatedArr);
  };

  const handleConfigureSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      configUser(selectedUser._id, selectedProperty, selectedMarkets)
    );
    history.push('/');
  };

  const cancelForm = async (e) => {
    e.preventDefault();
    await dispatch(setSelectedUser(null));
    history.push('/');
  };

  useEffect(() => {
    async function getHotelList() {
      await dispatch(fetchHotelsList(selectedMarket));
    }
    if (selectedMarket !== undefined) {
      getHotelList();
    }
  }, [dispatch, selectedMarket]);

  return (
    <Container>
      <Form onSubmit={handleConfigureSubmit}>
        <FormGroup className={classes.formControl + ' my-5'}>
          <FormLabel htmlFor="grouped-native-select">
            Select Destinations
          </FormLabel>
          <FormControl className={classes.formControl + ' mb-5'}>
            <InputLabel htmlFor="grouped-native-select">
              ID | Destination
            </InputLabel>
            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              onChange={handleMarketChange}
              value={selectedMarketIndx}
            >
              <option value={-100}>None</option>
              {markets.length > 0 ? (
                markets.map((d, index) => {
                  return (
                    <option value={index}>
                      {d.id} | {d.name}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
            <Box component="span" m={1}>
              {selectedMarkets.length > 0 ? (
                selectedMarkets.map((h, ind) => (
                  <Badge variant="secondary" className="mx-1 pe-auto">
                    {h.id} | {h.name} &nbsp;
                    <i
                      class="fas fa-times-circle"
                      onClick={() => removeSelectedMarket(h.id)}
                    ></i>
                  </Badge>
                ))
              ) : (
                <></>
              )}
            </Box>
          </FormControl>
          <FormLabel htmlFor="grouped-native-select">
            Select Properties
          </FormLabel>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-native-select">ID | Hotel</InputLabel>
            <Select
              native
              defaultValue=""
              id="grouped-native-select"
              onChange={handlePropertyChange}
              value={selectedPropertyIndx}
            >
              {hotelList.length > 0 ? (
                hotelList.map((h, index) => {
                  return (
                    <option value={index}>
                      {h.id} | {h.name}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
            <FormLabel className="mt-5" htmlFor="grouped-native-select">
              Selected Properties
            </FormLabel>
            <Box component="span" m={1}>
              {selectedProperty.length > 0 ? (
                (selectedProperty.length + ' Properties',
                selectedProperty.map((h, ind) => (
                  <Badge variant="secondary" className="mx-1 pe-auto">
                    {h.id} | {h.name} &nbsp;
                    <i
                      class="fas fa-times-circle"
                      onClick={() => removeSelectedProperty(h.id)}
                    ></i>
                  </Badge>
                )))
              ) : (
                <></>
              )}
            </Box>
          </FormControl>
          <Button
            variant="primary"
            type="submit"
            className={'btn btn-md btn-primary mt-5'}
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            className={'btn btn-md btn-secondary my-1'}
            onClick={cancelForm}
          >
            Cancel
          </Button>
        </FormGroup>
      </Form>
    </Container>
  );
};
