import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay } from '../../components/UI/LoadingOverlay';
import UserPage from '../../components/UserPage';
import Reports from '../../components/Reports';
import Markets from '../../components/Markets';
import { useSelector } from 'react-redux';

function Content() {
  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, auth_err, userList, navigation_tab } = auth;

  return (
    <>
      {auth_loading ? (
        <LoadingOverlay show={auth_loading} />
      ) : (
        <>
          {navigation_tab == 0 ? (
            <UserPage />
          ) : navigation_tab == 1 ? (
            <Reports />
          ) : navigation_tab == 2 ? (
            <Markets />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Content;
