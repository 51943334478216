import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { removeUser } from '../services/auth.service';
import { fetchUserList, setSelectedUser } from '../redux/actions/auth.actions';
import { useHistory } from 'react-router';
import { LoadingOverlay } from './UI/LoadingOverlay';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);
const useStyles = makeStyles({
  container: {
    maxHeight: window.innerHeight - 350,
  },
  table: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
});

export const UserGrid = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const { auth_loading, auth_err, userList } = auth;
  const dispatch = useDispatch();
  const history = useHistory();
  const Actions = ({ user }) => {
    const options = ['Edit', 'Remove'];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const getUsers = async () => {
      await dispatch(fetchUserList());
    };

    const handleAction = async (action) => {
      // console.log('event.target.value', action, ' _id: ', user);

      if (action === 0) {
        const selectUser = userList.find((u) => u._id === user._id);
        console.log('selectUser', selectUser);
        await dispatch(setSelectedUser(selectUser));
        history.push('/dialog');
      }

      if (action === 1) {
        if (
          window.confirm(
            `Are you sure to Remove user ${user.email} from the System?`
          )
        ) {
          await removeUser(user._id);

          await getUsers();
        }
      }

      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClick={handleClose}
          PaperProps={
            {
              // style: {
              //   maxHeight: ITEM_H * 4.5,
              //   width: '20ch',
              // },
            }
          }
        >
          {options.map((option, indx) => (
            <MenuItem
              key={option}
              //selected={option === 'Pyxis'}
              onClick={() => handleAction(indx)}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <div>
      {auth_loading ? (
        <LoadingOverlay />
      ) : auth_err ? (
        <Typography color="text.secondary" align="center">
          {auth_err}
        </Typography>
      ) : userList.length > 0 ? (
        <TableContainer
          component={Paper}
          className={classes.container + ' my-5'}
        >
          <Box>
            <Table
              className={classes.table}
              size="medium"
              aria-label="customized table"
            >
              <TableHead>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Id
                </StyledTableCell>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Role
                </StyledTableCell>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Created On
                </StyledTableCell>
                <StyledTableCell
                  className={classes.sticky}
                  style={{ fontWeight: 'bold', width: '250px' }}
                >
                  Action
                </StyledTableCell>
              </TableHead>
              <TableBody>
                {userList.length > 0 ? (
                  userList.map((e) => (
                    <StyledTableRow>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                      >
                        {e._id}
                      </StyledTableCell>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky + ' text-capitalize'}
                      >
                        {e.name}
                      </StyledTableCell>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                      >
                        {e.email}
                      </StyledTableCell>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky + ' text-capitalize'}
                      >
                        {e.role}
                      </StyledTableCell>
                      <StyledTableCell
                        size="small"
                        component="th"
                        scope="row"
                        className={classes.sticky}
                      >
                        {moment(e.date).format('YYYY-MM-DD')}
                      </StyledTableCell>
                      <StyledTableCell className={classes.sticky}>
                        <Actions user={e} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
};
