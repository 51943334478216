import axios from 'axios';
import { RapidAPIHost, RapidAPIKey } from '../env';

export const searchRegion = async (region) => {
  let results = [];

  const options = {
    method: 'GET',
    url: 'https://hotels-com-provider.p.rapidapi.com/v2/regions',
    params: {
      locale: 'en_US',
      query: region,
      domain: 'US',
    },
    headers: {
      'X-RapidAPI-Key': RapidAPIKey,
      'X-RapidAPI-Host': RapidAPIHost,
    },
  };

  try {
    await axios.request(options).then((response) => {
      const data = response.data.data;
      if (data.length > 0) {
        data.map((d, ix) => {
          if (d.type == 'CITY') {
            results.push({
              id: ix,
              gaiaId: d.gaiaId,
              name: d.regionNames.displayName,
              domain: d.hierarchyInfo.country.isoCode2,
              coordinates: d.coordinates,
            });
          }
        });
      }
    });
  } catch (error) {
    console.error(error);
  }

  return results;
};
