// export const apiURI = 'http://localhost:5000/api/';
// export const apiURI = 'https://service.ratebuckets.com/api/';
export const apiURI = 'https://api.ratebuckets.com/api/';

export const STRIPE_SECRET =
  'pk_test_51IwOoxEovqboeDOg8aSThtwu9jIJJChTqAeLvSzE4TqcF3koMOW1ZgTGY2Td4PYzsawZgI2MuJNbWmp2jpyKgfXX00dYqlOet5';

export const INTERNAL_SERVER_ERR =
  'Sorry, we were unable to process your request at this time.';

export const DATA_ERR =
  'Sorry, we were unable to process your request at this time.';

export const RapidAPIKey = '941b34aaf0mshcb297e061f0c463p13bb36jsnf2f6388c3c1c';

export const RapidAPIHost = 'hotels-com-provider.p.rapidapi.com';
