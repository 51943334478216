import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router';
import AlertDialog from './UI/Dialog/Dialog';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import { fetchUserList } from '../redux/actions/auth.actions';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { UserGrid } from './UserGrid';
import { ReportsGrid } from './ReportsGrid';
import { fetchAllReports } from '../services/reports';
import { useState } from 'react';
import { fetchMarkets } from '../redux/actions/cluster.actions';

const styles = (theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const Reports = (props) => {
  const { classes } = props;
  const history = useHistory();

  const [dialogOpen, setDialogState] = useState();

  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, auth_err, userList } = auth;

  const dispatch = useDispatch();

  const [reports_list, set_reports_list] = useState([]);

  const [load, set_load] = useState(true);

  useEffect(() => {
    const getReports = async () => {
      set_load(true);
      const reports = await fetchAllReports();
      set_reports_list(reports);
      console.log(reports);
      set_load(false);
    };
    getReports();
  }, []);

  const handleDialogClose = () => {
    setDialogState(false);
  };

  const handleDialogOpen = () => {
    setDialogState(true);
    // props.history.push({
    //   pathname: '/dialog',
    //   state: {
    //     _open: true,
    //   },
    // });
  };

  return (
    <div>
      {auth_loading ? (
        <LoadingOverlay />
      ) : auth_err ? (
        <Typography color="text.secondary" align="center">
          {auth_err}
        </Typography>
      ) : reports_list.length > 0 && !load ? (
        <Paper className={classes.paper + ' container'}>
          <AppBar
            className={classes.searchBar}
            position="static"
            color="default"
            elevation={0}
          >
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    placeholder="Search by email address, phone number, or user UID"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.searchInput,
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <Link>
                    {' '}
                    <Button
                      variant="contained"
                      className={classes.addUser}
                      onClick={handleDialogOpen}
                    >
                      New Report
                    </Button>{' '}
                  </Link>

                  <Tooltip title="Reload">
                    <IconButton>
                      <RefreshIcon className={classes.block} color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <div className={classes.contentWrapper}>
            {reports_list !== undefined && reports_list.length > 0 ? (
              <ReportsGrid reports_list={reports_list} />
            ) : (
              <Typography color="text.secondary" align="center">
                No Reports for this project yet
              </Typography>
            )}
          </div>
        </Paper>
      ) : (
        <></>
      )}
    </div>
  );
};

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reports);
