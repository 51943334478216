import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router';
import AlertDialog from './UI/Dialog/Dialog';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import { fetchUserList } from '../redux/actions/auth.actions';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { UserGrid } from './UserGrid';

const styles = (theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const UserPage = (props) => {
  const { classes } = props;
  const history = useHistory();

  const [dialogOpen, setDialogState] = React.useState();

  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, auth_err, userList } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    const getUsers = async () => {
      await dispatch(fetchUserList());
    };
    getUsers();
  }, []);

  const handleDialogClose = () => {
    setDialogState(false);
  };

  const handleDialogOpen = () => {
    setDialogState(true);
    // props.history.push({
    //   pathname: '/dialog',
    //   state: {
    //     _open: true,
    //   },
    // });
  };

  return (
    <div>
      <Paper className={classes.paper + ' container'}>
        <AppBar
          className={classes.searchBar}
          position="static"
          color="default"
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search by email address, phone number, or user UID"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: '/register',
                  }}
                >
                  {' '}
                  <Button
                    variant="contained"
                    className={classes.addUser}
                    onClick={handleDialogOpen}
                  >
                    Add user
                  </Button>{' '}
                </Link>

                <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          {userList !== undefined && userList.length > 0 ? (
            <UserGrid />
          ) : (
            <Typography color="text.secondary" align="center">
              No users for this project yet
            </Typography>
          )}
        </div>
      </Paper>
    </div>
  );
};

UserPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserPage);
