import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOverlay } from './UI/LoadingOverlay';
import { useState } from 'react';
import { fetchMarkets } from '../redux/actions/cluster.actions';
import { MarketsGrid } from './MarketsGrid';
import { PopupModal } from './UI/PopupModal';

const styles = (theme) => ({
  paper: {
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

const Markets = (props) => {
  const { classes } = props;
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, auth_err, userList } = auth;

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { markets } = getClusterDataSet;

  useEffect(() => {
    const getReports = async () => {
      const reports = await fetchMarkets();
    };
    getReports();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {auth_loading ? (
        <LoadingOverlay />
      ) : auth_err ? (
        <Typography color="text.secondary" align="center">
          {auth_err}
        </Typography>
      ) : markets.length > 0 ? (
        <>
          <PopupModal show={show} handleClose={handleClose} />
          <Paper className={classes.paper + ' container'}>
            <AppBar
              className={classes.searchBar}
              position="static"
              color="default"
              elevation={0}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      placeholder="Search by email address, phone number, or user UID"
                      InputProps={{
                        disableUnderline: true,
                        className: classes.searchInput,
                      }}
                      variant="standard"
                    />
                  </Grid>
                  <Grid item>
                    <Link>
                      {' '}
                      <Button
                        variant="contained"
                        className={classes.addUser}
                        onClick={handleShow}
                      >
                        New Market
                      </Button>{' '}
                    </Link>

                    <Tooltip title="Reload">
                      <IconButton>
                        <RefreshIcon
                          className={classes.block}
                          color="inherit"
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
              {markets !== undefined && markets.length > 0 ? (
                <MarketsGrid markets={markets} />
              ) : (
                <Typography color="text.secondary" align="center">
                  No markets for this project yet
                </Typography>
              )}
            </div>
          </Paper>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

Markets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Markets);
