import React, { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { searchRegion } from '../../services/service';
import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from '@material-ui/core';

export const MarketForms = () => {
  const [search_results, set_search_results] = useState([]);

  const [search_key, set_search_key] = useState('');

  const searchMarket = async () => {
    await searchRegion(search_key).then((results) => {
      console.log(results);
      set_search_results(results);
    });
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <div className="input-group-append">
          <Form.Control
            type="market_search"
            placeholder="Search Market"
            autoFocus
            value={search_key}
            onChange={(e) => set_search_key(e.target.value)}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={searchMarket}
          >
            <i className="fa fa-search" />
          </button>
        </div>
      </Form.Group>
      {search_results.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Select the Market
          </InputLabel>
          <NativeSelect
            defaultValue={0}
            inputProps={{
              name: 'age',
              id: 'uncontrolled-native',
            }}
          >
            <option value={0}>Select</option>
            {search_results.length > 0 ? (
              search_results.map((res) => (
                <option value={res.id}>{`${res.gaiaId} | ${res.name}`}</option>
              ))
            ) : (
              <></>
            )}
          </NativeSelect>
        </FormControl>
      ) : (
        <></>
      )}
    </Form>
  );
};
