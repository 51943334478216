import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';
import Paperbase from './modules/paperbase/Paperbase';
import PrivateRoute from './routes/PrivateRoute';
import AlertDialog from './components/UI/Dialog/Dialog';
import { UserCrud } from './components/UserCrud';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'f4f4f4',
  },
  toolbar: {
    color: '#f4f4f4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={Paperbase} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/register" component={Register} />
        <Route path="/dialog" component={UserCrud} />
      </Switch>
    </Router>
  );
}

export default App;
