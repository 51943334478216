import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Redirect, Route } from 'react-router';
import { LoadingOverlay } from '../components/UI/LoadingOverlay';
import { fetchUserList } from '../redux/actions/auth.actions';
import {
  fetchMarkets,
  fetchRefreshDates,
} from '../redux/actions/cluster.actions';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const { user, auth_loading, isLoggedIn } = auth;
  const dispatch = useDispatch();

  const getClusterDataSet = useSelector((state) => state.clusterDataSet);
  const { loading, err, markets, refreshDates } = getClusterDataSet;

  useEffect(() => {
    async function getMarkets() {
      await dispatch(fetchMarkets());
    }
    if (isLoggedIn) {
      getMarkets();
    }
  }, [dispatch, isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth_loading) {
          <LoadingOverlay show={auth_loading} />;
        } else {
          if (!isLoggedIn) {
            return <Redirect to="/login" />;
          } else if (isLoggedIn && markets.length > 0) {
            return <Component {...props} />;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
