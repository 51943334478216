import axios from 'axios';
import { apiURI } from '../env';
import { getReqHeaders } from './auth.service';

export const fetchAllReports = async () => {
  let res = [];
  await axios
    .get(`${apiURI}dashboard/get_reports`, {
      headers: await getReqHeaders(),
    })
    .then((result) => {
      res = result.data.data;
    })
    .catch((err) => {
      //console.log(err);
      // dispatch({
      //   type: ACTION_TYPES.LOGIN_FAILED,
      //   payload: err,
      // });
    });

  return res;
};
