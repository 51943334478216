export const GET_CLUSTER = 'GET_CLUSTER';
export const GET_CLUSTER_PROGRESS = 'GET_CLUSTER_PROGRESS';
export const GET_CLUSTER_FAILED = 'GET_CLUSTER_FAILED';

export const GET_HOTELS = 'GET_HOTELS';
export const GET_HOTELS_PROGRESS = 'GET_HOTELS_PROGRESS';
export const GET_HOTELS_FAILED = 'GET_HOTELS_FAILED';

export const GET_MARKETS = 'GET_MARKETS';
export const GET_MARKETS_PROGRESS = 'GET_MARKETS_PROGRESS';
export const GET_MARKETS_FAILED = 'GET_MARKETS_FAILED';

export const SET_CLUSTER_1 = 'SET_CLUSTER_1';
export const SET_CLUSTER_2 = 'SET_CLUSTER_2';
export const SET_CLUSTER_3 = 'SET_CLUSTER_3';
export const SET_CLUSTER_4 = 'SET_CLUSTER_4';

export const SET_QUARY = 'SET_QUARY';
export const SET_REQ_HOTEL = 'SET_REQ_HOTEL';

export const GET_HOTELSLIST = 'GET_HOTELSLIST';
export const GET_HOTELSLIST_PROGRESS = 'GET_HOTELSLIST_PROGRESS';
export const GET_HOTELSLIST_FAILED = 'GET_HOTELSLIST_FAILED';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_PROGRESS = 'LOGIN_PROGRESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_PROGRESS = 'REGISTER_PROGRESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_PROGRESS = 'LOGOUT_PROGRESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const ISLOGGEDIN_TRUE = 'ISLOGGEDIN_TRUE';
export const ISLOGGEDIN_FALSE = 'ISLOGGEDIN_FALSE';

export const GET_REFRESH_DATES = 'GET_REFRESH_DATES';
export const GET_REFRESH_DATES_PROGRESS = 'GET_REFRESH_DATES_PROGRESS';
export const GET_REFRESH_DATES_FAILED = 'GET_REFRESH_DATES_FAILED';

export const ISLOADING_TRUE = 'ISLOADING_TRUE';
export const ISLOADING_FALSE = 'ISLOADING_FALSE';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_PROGRESS = 'GET_USERS_LIST_PROGRESS';
export const GET_USERS_LIST_FAILED = 'GET_USERS_LIST_FAILED';

export const GET_NAVIGATION_TAB = 'GET_NAVIGATION_TAB';
export const SET_NAVIGATION_TAB = 'SET_NAVIGATION_TAB';
